<template>
  <v-snackbar
    v-model="show"
    :top="type === 'top'"
    :bottom="type === 'bottom'"
    :right="type === 'right'"
    :left="type === 'left'"
    :color="color"
    class="py-2"
  >
    <v-card-actions class="pa-0">
      <div>
        <v-icon color="white" v-if="color === 'success'">check_circle</v-icon>
        <v-icon color="white" v-if="color === 'info'">info</v-icon>
        <v-icon color="white" v-if="color === 'warning'">warning</v-icon>
        <v-icon color="white" v-if="color === 'error'">error</v-icon>
      </div>
      <div class="ml-5">
        <div v-if="Array.isArray(message)">
          <ul>
            <li v-for="(msg, index) in message" v-bind:key="index">
              {{ msg }}
            </li>
          </ul>
        </div>
        <div v-else-if="typeof message === 'object'">
          <div class="mb-2" v-for="(object, key) in message" v-bind:key="key">
            <p class="ma-0">{{ key | snakeToTitle }}</p>
            <ul>
              <li v-for="(list, index) in object" v-bind:key="index">
                {{ list }}
              </li>
            </ul>
          </div>
        </div>
        <p class="ma-0" v-else>{{ message }}</p>
      </div>
    </v-card-actions>
    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" color="white" text @click.native="show = false">
        <v-icon>close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      type: "top",
      message: "",
      color: "",
      timeout: 5000
    };
  },
  created: function() {
    this.$store.watch(
      state => state.snackbar.snack,
      () => {
        const message = this.$store.state.snackbar.snack.message;
        if (message) {
          this.show = true;
          this.message = message;
          this.type = this.$store.state.snackbar.snack.type ?? this.type;
          this.color = this.$store.state.snackbar.snack.color;
          this.$store.commit("snackbar/setSnack", {});
        }
      }
    );
  }
};
</script>
